module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"exclusieveaudi.nl","short_name":"ExclusieveAudi","start_url":"/?pwa=true","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/data/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"454ff0e928723c245cd43b54b6deb4bb"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-10656296-54","head":false,"anonymize":true,"respectDNT":true,"exclude":[],"defer":true,"enableWebVitalsTracking":true,"pageTransitionDelay":0},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://bee74dcb0b1c49289323c40e17b1d703@o409936.ingest.sentry.io/5480374","tracesSampleRate":1,"release":"gatsby@2022-07-03T23:03:41.628Z","environment":"production","enabled":true,"debug":false},
    },{
      plugin: require('../../../packages/gatsby-theme-menus/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../packages/gatsby-theme-apolloclient/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../themes/exclusieveauto/gatsby-browser.js'),
      options: {"plugins":[],"enableAds":true,"siteId":"5ff8514f02c0e00028cbc0df","sourceStrapi":{"vehicles/vehicles":{"qs":{"isSold_ne":true,"make":"Audi"}},"vehicles/images":{"qs":{"vehicle.isSold_ne":true,"vehicle.make":"Audi"}}},"vehicles":{"where":{"isSold_ne":true,"make":"Audi"}}},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
