import React from 'react';
import ErrorBoundary from 'gatsby-theme-core/src/components/error-boundary';

// eslint-disable-next-line import/prefer-default-export
export const wrapPageElement = ({
  element,
}) => (
  <ErrorBoundary>
    {element}
  </ErrorBoundary>
);

const HeadComponents = [
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" />,
];

export const onRenderBody = ({
  setHeadComponents,
}, pluginOptions) => {
  if (pluginOptions.enableAds) {
    // console.log('[gatsby-theme-exclusieveauto gatsby-ssr] Adding adsbygoogle.js');
    setHeadComponents(HeadComponents);
  } else {
    // console.log('[gatsby-theme-exclusieveauto gatsby-ssr] NOT adding adsbygoogle.js');
  }
};
