import { ApolloClient, InMemoryCache } from '@apollo/client';
import fetch from 'cross-fetch';
import { createUploadLink } from "apollo-upload-client";

if (!process.env.GATSBY_API_URL) {
  throw new Error('Missing process.env.GATSBY_API_URL');
}

// Instantiate required constructor fields
const cache = new InMemoryCache();
const link = createUploadLink({
  uri: `${process.env.GATSBY_API_URL}/graphql`,
  fetch,
});

const client = new ApolloClient({
  // Provide required constructor fields
  cache,
  link,

  // Provide some optional constructor fields
  // name: 'artz',
  // version: '1.0',
});

export default client;
